/* eslint-disable */
require('./alde-logo')
require('./ano-2011-logo')
require('./cdu-logo')
require('./ciu-logo')
require('./die-linke-logo')
require('./diem25-logo')
require('./edp-logo')
require('./egp-logo')
require('./epp-logo')
require('./euromat-logo')
require('./european-left-logo')
require('./european-spring-logo')
require('./european-stars')
require('./fdp-logo')
require('./fisumat-logo')
require('./fitomat-logo')
require('./grune-logo')
require('./jef-logo')
require('./linke-logo')
require('./neos-logo')
require('./ovld-logo')
require('./pes-logo')
require('./piraten-logo')
require('./pirates-logo')
require('./poe-colored-logo')
require('./poe-logo')
require('./polis-colored-logo')
require('./polis-logo')
require('./spd-logo')
require('./star')
require('./tierschutzpartei-logo')
require('./volt-logo')
